import { Bars3BottomLeftIcon } from "@heroicons/react/24/solid";
import cntl from "cntl";
import Link from "next/link";
import React from "react";
import { CapabilityType, useUser } from "~/components/hooks/useUser";
import LinkButton from "../common/buttons/LinkButton";
import { LogoLink } from "./LogoLink";
import NavDropdown from "./NavDropdown";
import ProfileMenu from "./ProfileMenu";

interface HeaderProps {
  setSidebarOpen?: (arg0: boolean) => void;
  useProgramsSidebar?: boolean;
  preventNav?: boolean;
  selectedPage: "tracks" | "payroll" | "funding";
}

const Header: React.FC<HeaderProps> = ({
  setSidebarOpen,
  useProgramsSidebar = false,
  preventNav = false,
  selectedPage,
}) => {
  const { data: user, isLoading, error } = useUser();

  return (
    <>
      <div className="fixed top-0 z-20 flex h-16 w-full border-b border-borderGrey bg-white">
        {setSidebarOpen && (
          <button
            type="button"
            className={cntl`
              focus:ring-indigo-500 flex
              flex-col
              items-center
              justify-center
              border-r
              border-gray-200
              px-4
              text-gray-500
              focus:outline-none
              focus:ring-2
              focus:ring-inset ${
                useProgramsSidebar ? "lg:hidden" : "md:hidden"
              }`}
            onClick={() => setSidebarOpen(true)}
          >
            <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
            <span className="text-sm uppercase">Menu</span>
          </button>
        )}
        <div className="flex flex-1 justify-between px-4">
          <div className="flex-2 flex">
            <div className="mr-8 flex items-center">
              <LogoLink style="Responsive" />
            </div>
          </div>
          {!preventNav && [
            <div
              className="flex-2 border-sky-500 my-4 hidden items-center justify-center border-l-2 border-solid sm:flex"
              key="tracks-link"
            >
              <Link href="/tracks" passHref>
                <a
                  className={`m-8 uppercase ${
                    selectedPage == "tracks"
                      ? "text-petrolGreen"
                      : "text-darkBlue"
                  } cursor-pointer no-underline active:text-petrolGreen`}
                >
                  Tracks
                </a>
              </Link>
            </div>,
            user?.capabilities.includes("PAYROLL") && (
              <div
                className="flex-2 border-sky-500 my-4 hidden items-center justify-center border-l-2 border-solid sm:flex"
                key="payroll-link"
              >
                <Link href="/payroll" passHref>
                  <a
                    className={`m-8 uppercase ${
                      selectedPage == "payroll"
                        ? "text-petrolGreen"
                        : "text-darkBlue"
                    } cursor-pointer no-underline active:text-petrolGreen`}
                  >
                    Payroll
                  </a>
                </Link>
              </div>
            ),
            <div
              className="flex-2 border-sky-500 my-4 hidden items-center justify-center border-l-2 border-solid sm:flex"
              key="funding-link"
            >
              <Link href="/" passHref>
                <a
                  className={`m-8 uppercase ${
                    selectedPage == "funding"
                      ? "text-petrolGreen"
                      : "text-darkBlue"
                  } cursor-pointer no-underline active:text-petrolGreen`}
                >
                  Funding
                </a>
              </Link>
            </div>,
          ]}
          <div className="flex flex-1" />
          {!preventNav && user && user.isMember && (
            <div className="flex-2 flex items-center justify-center sm:hidden">
              <NavDropdown selection={selectedPage} />
            </div>
          )}
          <div className="flex-2 flex items-center">
            {!preventNav && user && !user.isMember && (
              <LinkButton href={"/pricing"} color="petrolGreen">
                Upgrade
              </LinkButton>
            )}
          </div>
          <div
            className="flex-4 ml-6 flex items-center sm:ml-6 
            sm:max-w-[200px] 
            md:max-w-[300px] 
            lg:max-w-[575px]"
          >
            {/* Profile dropdown */}
            {!preventNav && user && <ProfileMenu user={user} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
